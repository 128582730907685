import { useState } from "react";
import {Breakpoint} from "../constants";
import styled from "styled-components";
import ActivityIndicator from "./ActivityIndicator";
import XCloseIcon from '../assets/images/close-x.svg';

export const ModalContainer = ({close}:any) => {

  const [downloading, setDownloading] = useState(false);

  async function handleDownload(fileUrl:string) {
      setDownloading(true);
      // await new Promise((resolve) => setTimeout(resolve, 5000))
      fetch(`/downloads/${fileUrl}`).then(response => {
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = fileUrl;
              alink.click();
              setDownloading(false);
          })
      })
  }

  return (
      <ModalContainerInner>
          <ModalBox>
              {
                  downloading && (
                    <DownloadingIndicator>
                        <ActivityIndicator height={3} color={'#ffffff'}/>
                        <Text size={0.5} color={'#ffffff'} letterspacing={0.01}  style={{marginTop:'0.5rem'}}>downloading... please wait...</Text>
                    </DownloadingIndicator>
                  )
              }
              <CloseButton onClick={() => close()} />
              <ContentBox paddingLeft={4}>
                  <Col marginBottom={1}>
                      <Text size={1.6} color="#ffffff" letterspacing={0.01}>For Retails</Text>
                  </Col>
                  <Col marginBottom={4}>
                      <Text size={1} color="#ffffff" letterspacing={0.01} style={{fontFamily:'poppins-light',fontStyle:'italic'}}>Retail shops, food chains, offices</Text>
                  </Col>
                  <Col>
                      <DocImage src={require('../assets/images/cms/dl-retails-doc.svg').default}/>
                  </Col>
                  <Col>
                      <DownloadDeckButton onClick={() => handleDownload('DO-XX_Retail_deck.pdf')}>
                        Download Deck
                    </DownloadDeckButton>
                  </Col>
              </ContentBox>
              <ContentBox paddingRight={4}>
                  <Col marginBottom={1}>
                      <Text size={1.6} color="#ffffff" letterspacing={0.01}>For Events</Text>
                  </Col>
                  <Col marginBottom={4}>
                      <Text size={1} color="#ffffff" letterspacing={0.01} style={{fontFamily:'poppins-light',fontStyle:'italic'}}>Galleries, exhibitions & seminars</Text>
                  </Col>
                  <Col>
                      <DocImage src={require('../assets/images/cms/dl-events-doc.svg').default}/>
                  </Col>
                  <Col>
                      <DownloadDeckButton onClick={() => handleDownload('DO-XX_Activation_deck.pdf')}>
                        Download Deck
                    </DownloadDeckButton>
                  </Col>
              </ContentBox>
          </ModalBox>
      </ModalContainerInner>
  )
}

const Text = styled('span')<{color?:string,size?:number,letterspacing?:number,normal?:boolean,lineHeight?:number}>`
    font-family: ${props => props.normal ? 'syncopate-regular' : 'syncopate-bold'};
    font-size: ${props => props.size || 4.2}vw;
    color: ${props => props.color || '#000'};
    line-height: ${props => props.lineHeight || 0.9};
    letter-spacing: ${props => props.letterspacing || -0.3}rem;
    word-wrap: break-word;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: ${props => (props.size ? props.size * 1.2 : 0) || 5}vh;
        line-height: 1; 
    }    
`

const ModalContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${props => Breakpoint.md}) {
      padding: 0 2vw;
  }    
`
const ModalBox = styled.div`
  position: relative;
  height: 60vh;
  border-radius: 0.8rem;
  background-color: #3F3F3F;
  display: flex;
  justify-content: space-between;
  gap: 1.5vw;
  overflow: hidden;
  @media (max-width: ${props => Breakpoint.md}) {
      width: 100%;
  }    
  @media (max-width: ${props => Breakpoint.md2}) {
      flex-direction: column;
      height: 65vh;
  }    
`
const ContentBox = styled('div')<{ paddingLeft?: number, paddingRight?: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${props => props.paddingLeft}rem;
  padding-right: ${props => props.paddingRight}rem;
  @media (max-width: ${props => Breakpoint.md2}) {
      width: 100%;
      height: 50%;
      padding-left: 0;
      padding-right: 0;
  }    
`
const CloseButton = styled.div`
  position: absolute;
  width: 1.3vh;
  height: 1.3vh;
  background-image: url(${props => XCloseIcon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  top: 2.5%;
  right: 2%;        
  cursor: pointer;
`
const DocImage = styled.img`
  height: 9vw;
  @media (max-width: ${props => Breakpoint.md2}) {
      height: 7rem;
      margin-top: -1rem;
  }    
`
const DownloadDeckButton = styled.div`
  padding: 1.2vw 1.5vw 1vw;
  border: 0.1vw solid #A5E5E7;
  border-radius: 4vw;
  margin-top: 2rem;
  cursor: pointer;
  font-family: syncopate-bold;
  font-size: 1.5vh;
  color: #A5E5E7;
  white-space: nowrap;
  @media (max-width: ${props => Breakpoint.md2}) {
      padding: 1rem 1.5rem;
      border-radius: 2rem;
      margin-top: 1rem;
  }    
`
const DownloadingIndicator = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

const Col = styled('div')<{marginBottom?:number}>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${props => props.marginBottom}vh;
`