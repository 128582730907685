import React from 'react';
import styled, {keyframes} from "styled-components";
import logoDoxxSmallWhite from "../assets/images/doxx-logo-small-white.svg";
import ScrollArrow from "../components/ScrollArrow";
import {Breakpoint} from "../constants";
import {DoText, DoTextSmaller} from "../Styles";
import doxxBgThirdPage from "../assets/images/doxx-bg-third-page.png";
import doxxHeroThirdPage from "../assets/images/doxx-hero-third-page.png";
import doxxIconCloud from "../assets/images/doxx-icon-cloud.png";
import doxxIconBuilding from "../assets/images/doxx-icon-building.png";
import doxxIconLaptop from "../assets/images/doxx-icon-laptop.png";
import { ModalContainer } from '../components/ModalContainer';
import { useModalContext } from '../hooks/ModalContext';


export default function ThirdPage() {

    // @ts-ignore
    const {setOpen,setModalChildren} = useModalContext();

    function handleRedirectToMain() {
        window.location.reload();
    }

    function handleLearnMoreClick() {
        setModalChildren(<ModalContainer close={() => setOpen(false)}/>);
        setOpen(true);
    }


    return (
        <Container>
            <BoxGrid>
                <Box>
                    <BoxIcon src={doxxIconCloud} />
                    <BoxTextWrapper>
                        <DoTextSmaller>Unlimited Cloud Storage</DoTextSmaller>
                    </BoxTextWrapper>
                </Box>
                <Box>
                    <BoxIcon src={doxxIconBuilding} />
                    <BoxTextWrapper>
                        <DoTextSmaller>Small Medium Enterprise + Enterprise Level</DoTextSmaller>
                    </BoxTextWrapper>
                </Box>
                <Box>
                    <BoxIcon src={doxxIconLaptop} />
                    <BoxTextWrapper>
                        <DoTextSmaller>Mobile + Desktop friendly - CMS Dashboard</DoTextSmaller>
                    </BoxTextWrapper>
                </Box>
            </BoxGrid>
            <FlexWrapper>
                <HeroImage src={doxxHeroThirdPage} />
                <DoTextWrap>
                    <DoText color="white">CMS Software +</DoText>
                    <DoText color="white">Digital Screen</DoText>
                    <DoText color="white">included</DoText>
                    <LearnMoreButton onClick={handleLearnMoreClick}/>
                </DoTextWrap>
            </FlexWrapper>
            <PageFooter>
                <ContactUs href="mailto:hello@do-xx.com" color="#fff">contact us</ContactUs>
                <BulletLine bulletPos="left" white />
                <FooterLogo src={logoDoxxSmallWhite} onClick={handleRedirectToMain} style={{marginRight:'2vw'}}/>
                {/*<ScrollArrowUpDown src={imageScrollArrowUpDownBlack} style={{marginRight:'1vw'}}/>*/}
                <ScrollArrow pageNum={3} white/>
            </PageFooter>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 6vh;
    padding: 10vh 3vw;
    flex-direction: column;
    background-image: url(${doxxBgThirdPage});
    background-size: cover;
    @media (max-width: ${props => Breakpoint.md}) {
        padding-top: 20px;
    }
`
const FlexWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 35vh;
    bottom: 0;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
        justify-content: center;
    }
`;
const HeroImage = styled.img`
    height: 25vw;
    object-fit: cover;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`;

const DOXX_SCROLLING_HEIGHT = 48;
const DoxxLogoScrolling = styled.div`
    position: relative;
    width: 100%;
    height: ${props => DOXX_SCROLLING_HEIGHT}vh;
    display: flex;
    align-items: center;
`
const DoTextWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3vw;
    @media (max-width: ${props => Breakpoint.md}) {
        padding-right: 3vw;
    }
`
const DoxxLogoScrollingContent = styled.div`
    display: flex;
    width: auto;
`
const DoxxLogoBanner = styled('img')<{toLeft?:boolean}>`
    height: ${props => DOXX_SCROLLING_HEIGHT * 0.7}vh;
    margin-right: 3vw;
    animation: ${props => props.toLeft ? slideshowToLeft : slideshowToRight} 12s linear infinite; 
`
const slideshowToLeft = keyframes`
    0%{transform: translateX(0);}
    100%{transform: translateX(-100%);}
`
const slideshowToRight = keyframes`
    0%{transform: translateX(-100%);}
    100%{transform: translateX(0%);}
`
const PageFooter = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;    
    align-items: center;
    height: 14vh;
    padding 0 3vw 0 3vw;
`
const ContactUs = styled('a')<{color?:string}>`
    font-family: syncopate-bold;
    font-size: 2.3vh;
    color: ${props => props.color || '#000'};
    text-decoration: underline;
    margin-top: 3.5vh;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 1.6vh;        
    }
`
const FooterLogo = styled.img`
    width: 5%;
    width: 9vh;
    cursor: pointer;
    @media (max-width: ${props => Breakpoint.md}) {
        width: 7vh;
    }
`
const BulletLine = styled('div')<{bulletPos?:string,white?:boolean}>`
    width: 83%;
    height: 1.2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1vh;
    margin-top: 3.5vh;
    ${props => props.bulletPos == 'left' ?
    `
            &:before {
                content: '';
                display: inline-block;
                width: 1.4vh !important;
                height: 1.4vh !important;
                border-radius: 50%;
                background-color: ${props.white ? '#fff' : '#000'};
            }
            &:after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 0.15vh;
                background-color: ${props.white ? '#fff' : '#000'};
                margin: 0 1vh;
            }               
        ` :
    `
            &:before {
                content: '';
                display: inline-block;
                width: 100%;
                height: 0.15vh;
                background-color: ${props.white ? '#fff' : '#000'};
                margin: 0 1vh;
            }
            &:after {
                content: '';
                display: inline-block;
                width: 1.4vh;
                height: 1.4vh;
                border-radius: 50%;
                background-color: ${props.white ? '#fff' : '#000'};
            }        
        `
};
    @media (max-width: ${props => Breakpoint.md}) {
        ${props => props.bulletPos == 'left' ?
    `
                &:before {
                width: 1vh !important;
                height: 1vh !important;
                }
            ` :
    `
                &:after {
                width: 1vh !important;
                height: 1vh !important;
                }                       
            `
}
    }
    
`

const BoxGrid = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
        padding-top: 0;
        gap: 2vh;
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    position: relative;
    height: 20vh;
    padding: 6vh 1.5vw 1.5vw 1.5vw;
    border-radius: 1vh;
    z-index: 1;
    width: 32%;
    @media (max-width: ${props => Breakpoint.lg}) {
        height: 25vh;
    }
    @media (max-width: ${props => Breakpoint.md}) {
        width: 100%;
        padding: 2vh;
        height: auto;
    }
`

const BoxTextWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    @media (max-width: ${props => Breakpoint.md}) {
        height: auto;
    }
`

const BoxIcon = styled.img`
    width: 18vh;
    margin-bottom: 2vh;
    position: absolute;
    left: 3vh;
    top: -9vh;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`

const LearnMoreButton = styled.div`
    width: 15vw;
    height: 4.5vw;
    background-color: #D7FF39;
    border-radius: 4.5vw;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    align-items: center;
    cursor: pointer;
    &:after {
        content: 'Learn More';
        font-family: syncopate-bold;
        font-size: ${props => 5 * 0.25}vw;
        color: #000000;
        line-height: 0.9;
    }
    @media (max-width: ${props => Breakpoint.md}) {
        width: 28vh;
        height: 7.3vh;
        border-radius: 7.3vh;
        &:after {
            font-size: ${props => 5.3 * 0.4}vh;
        }
    }
`