import React, { useEffect } from 'react';
import styled, {keyframes} from "styled-components";
import logoDoxxSliding from "../assets/images/doxx-sliding.svg";
import logoDoxxSmall from "../assets/images/doxx-logo-small.svg";
import doxxBgBrandsPage from "../assets/images/doxx-bg-brands-page.png";
import doxxIconsBrandsPage from "../assets/images/doxx-icons-second-page.png";
import ScrollArrow from "../components/ScrollArrow";
import {Breakpoint} from "../constants";
import {DoText, DoTextSmaller} from "../Styles";
import {gsap} from "gsap";

const brands = [
    {name: "avenueco", image: require("../assets/images/brands-new/avenue_co.png").default},
    {name: "beechoo", image: require("../assets/images/brands-new/bee_choo.png").default},
    {name: "chongqinggrilledfish", image: require("../assets/images/brands-new/chong_qing.png").default},
    {name: "coplay", image: require("../assets/images/brands-new/co_play.png").default},
    {name: "ein", image: require("../assets/images/brands-new/ein.png").default},
    {name: "fairygroupventures", image: require("../assets/images/brands-new/fairy_group.png").default},
    {name: "foodfest", image: require("../assets/images/brands-new/foodfest.png").default},
    {name: "jinjjachicken", image: require("../assets/images/brands-new/jinjja.png").default},
    {name: "jinkimchi", image: require("../assets/images/brands-new/jin_kimchi.png").default},
    {name: "kopifellas", image: require("../assets/images/brands-new/kopifellas.png").default},
    {name: "lauwang", image: require("../assets/images/brands-new/lau_wang.png").default},
    {name: "lloyds", image: require("../assets/images/brands-new/lloyds.png").default},
    {name: "mc", image: require("../assets/images/brands-new/m.png").default},
    {name: "myfirst", image: require("../assets/images/brands-new/my_first.png").default},
    {name: "nanyang", image: require("../assets/images/brands-new/nanyang.png").default},
    {name: "nasilemak", image: require("../assets/images/brands-new/nasi_lemak.png").default},
    {name: "nuodle", image: require("../assets/images/brands-new/nuodle.png").default},
    {name: "rb", image: require("../assets/images/brands-new/rb.png").default},
    {name: "ritzapplestrudel", image: require("../assets/images/brands-new/ritz.png").default},
    {name: "sancha", image: require("../assets/images/brands-new/sanchen.png").default},
    {name: "siltronic", image: require("../assets/images/brands-new/siltronic.png").default},
    {name: "springleaf", image: require("../assets/images/brands-new/springleaf.png").default},
    {name: "sweeheng", image: require("../assets/images/brands-new/sweeheng.png").default},
    {name: "thehainanstory", image: require("../assets/images/brands-new/the_hainan_story.png").default},
    {name: "timbregroup", image: require("../assets/images/brands-new/timbre_group.png").default},
    {name: "timebreplus", image: require("../assets/images/brands-new/timbe_plus.png").default},
    {name: "westernunion", image: require("../assets/images/brands-new/western_union.png").default}
];



export default function BrandsPage() {

    function handleRedirectToMain() {
        window.location.reload();
    }  

    return (
        <Container>
            <FlexWrapper>
                <DoTextWrap>
                    <DoText>Brands</DoText>
                </DoTextWrap>
                <BrandsGrid>
                    {
                        brands.map((brand, i) => {
                            return (
                                <BrandImageWrap key={`brand-${i}`}>
                                    <BrandImage src={brand.image} />
                                </BrandImageWrap>
                            )
                        })
                    }                
                </BrandsGrid>
                <BrandsGridMobile>
                    {
                        brands.map((brand, i) => {
                            return (
                                <BrandImageWrap className="brand-image-wrap" key={`brand-${i}`}>
                                    <BrandImage src={brand.image} />
                                </BrandImageWrap>
                            )
                        })
                    }
                </BrandsGridMobile>                
                <DoTextSmallerWrap>
                    <DoTextSmaller>And many more</DoTextSmaller>
                </DoTextSmallerWrap>
            </FlexWrapper>
            <PageFooter>
                <ContactUs style={{marginRight:'2vw'}} href="mailto:hello@do-xx.com">contact us</ContactUs>
                <BulletLine/>
                <FooterLogo src={logoDoxxSmall} onClick={handleRedirectToMain} style={{marginRight:'2vw'}}/>
                <ScrollArrow pageNum={4}/>
            </PageFooter>

        </Container>
    );
}

const brandsAnimation = keyframes`
    0%{transform: translateX(0);}
    50%{transform: translateX(-60%);}
    100%{transform: translateX(0);}
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${doxxBgBrandsPage});
    background-size: cover;
`

const DoTextWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 4vh 5vh;
    @media (max-width: ${props => Breakpoint.md}) {
        padding: 5vh 5vw 1vh;
        width: 100%;
    }
`

const DoTextSmallerWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding: 5vw 5vh;
    @media (max-width: ${props => Breakpoint.md}) {
        width: 100%;
    }
`

const PageFooter = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;    
    align-items: center;
    height: 14vh;
    padding 0 3vw 0 3vw;
`
const ContactUs = styled('a')<{color?:string}>`
    font-family: syncopate-bold;
    font-size: 2.3vh;
    color: ${props => props.color || '#000'};
    text-decoration: underline;
    margin-top: 3.5vh;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 1.6vh;        
    }
`
const BulletLine = styled('div')<{bulletPos?:string,white?:boolean}>`
    width: 83%;
    height: 1.2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1vh;
    margin-top: 3.5vh;
    ${props => props.bulletPos === 'left' ?
    `
            &:before {
                content: '';
                display: inline-block;
                width: 1.4vh !important;
                height: 1.4vh !important;
                border-radius: 50%;
                background-color: ${props.white ? '#fff' : '#000'};
            }
            &:after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 0.15vh;
                background-color: ${props.white ? '#fff' : '#000'};
                margin: 0 1vh;
            }               
        ` :
    `
            &:before {
                content: '';
                display: inline-block;
                width: 100%;
                height: 0.15vh;
                background-color: ${props.white ? '#fff' : '#000'};
                margin: 0 1vh;
            }
            &:after {
                content: '';
                display: inline-block;
                width: 1.4vh;
                height: 1.4vh;
                border-radius: 50%;
                background-color: ${props.white ? '#fff' : '#000'};
            }        
        `
};
    @media (max-width: ${props => Breakpoint.md}) {
        ${props => props.bulletPos === 'left' ?
    `
                &:before {
                width: 1vh !important;
                height: 1vh !important;
                }
            ` :
    `
                &:after {
                width: 1vh !important;
                height: 1vh !important;
                }                       
            `
}
    }
    
`
const FooterLogo = styled.img`
    width: 5%;
    width: 9vh;
    cursor: pointer;
    @media (max-width: ${props => Breakpoint.md}) {
        width: 7vh;
    }
`

const FlexWrapper = styled.div`
    display: flex;
    align-items: start;
    padding: 0 4vh;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    position: relative;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
        justify-content: center;
    }
`;

const BrandsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 10px;
    width: 100%;
    height: 100%;
    padding: 0 4vh;
    @media (max-width: ${props => Breakpoint.md}) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        display: none;
    }
`

const BrandsGridMobile = styled.div`
    display: none;
    padding: 4vh;
    overflow: visible;
    grid-template-columns: repeat(9, 100px);
    grid-gap: 10px;
    width: auto;
    animation: ${brandsAnimation} 10s ease-in-out reverse infinite;
    @media (max-width: ${props => Breakpoint.sm}) {
        display: grid;
    }
`

const BrandImageWrap = styled.div`
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 1vh;
    border-radius: 1vh;
    @media (max-width: ${props => Breakpoint.sm}) {
        height: 100px;
    }
`
const BrandImage = styled('div')<{src?:string}>`
    width: 100%;
    height: 100%;
    display: flex;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-image: url(${props => props.src});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;    
    }
`