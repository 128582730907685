import React, {useEffect} from 'react';
import ScrollArrow from "../components/ScrollArrow";
import styled from "styled-components";
import {Breakpoint} from "../constants";
import {createRoot} from "react-dom/client";
import TermsModal from "../components/TermsModal";
import doxxBgThirdPage from "../assets/images/doxx-bg-third-page.png";
import doxxMascot2 from "../assets/images/doxx-mascot-2.png";
import countrySingapore from "../assets/images/country-singapore.png";
import countryMalaysia from "../assets/images/country-malaysia.png";
import countryThailand from "../assets/images/country-thailand.png";
import countryPhilippines from "../assets/images/country-philippines.png";
import countryTaiwan from "../assets/images/country-taiwan.png";

import {gsap} from "gsap";
import { DoTextSmaller } from '../Styles';

export default function FooterPage({showTerms}:any) {

    function handleContactUsClick() {
        window.location.assign("mailto:hello@do-xx.com");
    }

    function handleIgClick() {
        window.open("https://www.instagram.com/doxx_studio/", "_blank");
    }

    function handleTermsClick() {
        if (showTerms) {
            showTerms();
        }
    }


    return (
        <Container>
            <Content>
                <ContentDesc>
                    <FlexWrapper>
                        <div>
                        <MainFooterTextWrap>
                            <MainFooterText color={'#D7FF39'}>We&nbsp;<MainFooterText color={'#fff'}>Discuss &bull; Talk &bull;</MainFooterText></MainFooterText>
                            <MainFooterText color={'#fff'}>Listen &bull; Collaborate &bull;</MainFooterText>
                            <MainFooterText color={'#fff'}>Partner &bull; Hire</MainFooterText>
                            <MainFooterText color={'#D7FF39'}>Do You?</MainFooterText>
                        </MainFooterTextWrap>
                        <MainFooterTextWrapMobile>
                            <MainFooterText color={'#D7FF39'}>We&nbsp;<MainFooterText color={'#fff'}>Discuss &bull; </MainFooterText></MainFooterText>
                            <MainFooterText color={'#fff'}>Talk &bull; Listen &bull;</MainFooterText>
                            <MainFooterText color={'#fff'}>Collaborate &bull; </MainFooterText>
                            <MainFooterText color={'#fff'}>Partner &bull; Hire</MainFooterText>
                            <MainFooterText color={'#D7FF39'}>Do You?</MainFooterText>
                        </MainFooterTextWrapMobile>                        
                        <ButtonGroup>
                            <ContactUsButton onClick={handleContactUsClick}/>&nbsp;
                            <SmallButton src={require('../assets/images/ig.svg').default} bgColor={'#9516A3'} onClick={handleIgClick}/>&nbsp;
                        </ButtonGroup>                        
                        <ButtonGroupMobile>
                            <ContactUsButton onClick={handleContactUsClick}/>
                            <div style={{display:'flex',justifyContent:'space-between',margin:'1vh 0',width:'28vh'}}>
                                <SmallButton src={require('../assets/images/ig.svg').default} bgColor={'#9516A3'} onClick={handleIgClick}/>
                            </div>
                        </ButtonGroupMobile>                        
                        </div>
                        <MascotWrapper>
                            <Mascot src={doxxMascot2} />
                        </MascotWrapper>
                    </FlexWrapper>

                    <Bottom>
                        <Countries>
                            <Country>
                                <CountryIcon src={countrySingapore}/>
                                <DoTextSmaller size={1.75} color={'#fff'}>Singapore</DoTextSmaller>
                            </Country>
                            <Country>
                                <CountryIcon src={countryMalaysia}/>
                                <DoTextSmaller size={1.75} color={'#fff'}>Malaysia</DoTextSmaller>
                            </Country>
                            <Country>
                                <CountryIcon src={countryThailand}/>
                                <DoTextSmaller size={1.75} color={'#fff'}>Thailand</DoTextSmaller>
                            </Country>
                            <Country>
                                <CountryIcon src={countryPhilippines}/>
                                <DoTextSmaller size={1.75} color={'#fff'}>Philippines</DoTextSmaller>
                            </Country>
                            <Country>
                                <CountryIcon src={countryTaiwan}/>
                                <DoTextSmaller size={1.75} color={'#fff'}>Taiwan</DoTextSmaller>
                            </Country>
                        </Countries>
                        <MainFooter>
                            <MainFooterTM>2024 DO-XX Studio Ltd.</MainFooterTM>
                            <MainFooterBulletLine color="#ffffff"/>
                            <PressTermWrap>
                                <MainFooterPressTerms>Press</MainFooterPressTerms>
                                <MainFooterPressTerms style={{marginRight:'2vw'}} onClick={handleTermsClick}>Terms</MainFooterPressTerms>
                            </PressTermWrap>
                            <div style={{marginRight:'2vw'}}>
                                <ScrollArrow arrowDownHidden pageNum={9} white/>
                            </div>
                        </MainFooter>
                        <PressTermWrapMobile>
                            <MainFooterPressTerms>Press</MainFooterPressTerms>
                            <MainFooterPressTerms onClick={handleTermsClick}>Terms</MainFooterPressTerms>
                        </PressTermWrapMobile>
                    </Bottom>
                </ContentDesc>
            </Content>
        </Container>
    );
}


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`
const MainFooterTextWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 3vw;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`
const MainFooterTextWrapMobile = styled.div`
    width: 100%;
    display: none;
    flex-direction: column;
    padding-top: 12vw;
    padding-left: 3vw;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
    }
`
const MainFooterText = styled('span')<{color?:string}>`
    font-family: syncopate-bold;
    font-size: 3.6vw;
    color: ${props => props.color || '#000'};
    letter-spacing: -0.2vw; 
    line-height: 1;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 4.3vh;    
    }
`

const Bottom = styled.div`
    width: 100%;
    position: absolute;
    bottom: 3vh;
    padding 0 0 0 3vw;
    color: white;
`
const MainFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;    
    align-items: center;
`
const MainFooterTM = styled.span`
    font-family: syncopate-bold;
    font-size: 2.3vh;
    color: #fff;
    white-space: nowrap;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 1.6vh;        
    }
`
const MainFooterBulletLine = styled.div`
    width: 100%;
    height: 1.2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
    &:before {
        content: '';
        display: inline-block;
        width: 1.4vh !important;
        height: 1.4vh !important;
        border-radius: 50%;
        background-color: #fff;
    }
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 0.15vh;
        background-color: #fff;
        margin: 0 1vh;
    }               
};
    
`
const ButtonGroup = styled.div`
    width: 100%;
    padding-left: 3vw;
    gap: 1vh;
    display: flex;
    margin-top: 3vh;
    flex-direction: row;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`
const ButtonGroupMobile = styled.div`
    width: 24vh;
    display: none;
    flex-direction: column;
    gap: 1vh;
    padding-left: 3vw;
    margin-top: 2vh;
    margin-bottom: 14vh;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
    }

`
const ContactUsButton = styled.div`
    width: 20vw;
    height: 4.5vw;
    background-color: #D7FF39;
    border-radius: 4.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:after {
        content: 'contact us';
        font-family: syncopate-bold;
        font-size: ${props => 5 * 0.25}vw;
        color: #000;
        line-height: 0.9;
    }
    @media (max-width: ${props => Breakpoint.md}) {
        width: 28vh;
        height: 7.3vh;
        border-radius: 7.3vh;
        &:after {
            font-size: ${props => 5.3 * 0.4}vh;
        }
    }
`
const SmallButton = styled('div')<{src?:string,bgColor?:string}>`
    width: 7vw;
    height: 4.5vw;
    background-color: ${props => props.bgColor};
    border-radius: 4.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:after {
        content: '';
        display: inline-block;
        width: 4.5vw;
        height: 4.5vw;
        background-image: url(${props => props.src});
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: center;
    }
    @media (max-width: ${props => Breakpoint.md}) {
        width: 13vh;
        height: 7.3vh;
        border-radius: 7.3vh;
        &:after {
            width: 7.3vh;
            height: 7.3vh;        
            background-size: 45%;
        }
    }
`
const PressTermWrap = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`
const PressTermWrapMobile = styled.div`
    width: 100%;
    display: none;
    justify-content: space-between;
    // padding-left: 3vh;
    padding-right: 3vh;
    // padding-bottom: 3vh;
    margin-top: 3vh;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
        padding-left: 0;
    }
`
const MainFooterPressTerms = styled.span`
    font-family: syncopate-bold;
    font-size: 2.3vh;
    text-decoration: underline;
    margin-left: 2.5vw;    
    white-space: nowrap;
    cursor: pointer;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 2.1vh;
        margin: 0;    
    }
`

const Header = styled.div`
    font-family: syncopate-bold;
    font-size: 5vh;
    color: #000000;
    width: 100%;
    height: 17%;
    padding-left: 3vw;
    display: flex;
    align-items: center;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 4vh;        
        height: 13%;
    }
`
const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: ${props => Breakpoint.md}) {
        // flex-direction: column;
        height: 100%;
    }        
`
const ContentDesc = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: url(${doxxBgThirdPage});
    background-size: cover;
`

const MascotWrapper = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
`

const Mascot = styled.img`
    max-width: 40vh;
    z-index: 10;
    object-fit: contain;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`

const FlexWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 80%;
    align-items: center;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
    }
`

const Countries = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3vh;
    gap: 3vw;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
        align-items: start;
    }
`

const Country = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
`

const CountryIcon = styled.img`
    width: 3vh;
    height: 3vh;
`