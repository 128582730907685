import React from 'react';
import ScrollArrow from "../components/ScrollArrow";
import logoDoxxSmallWhite from "../assets/images/doxx-logo-small-white.svg";
import styled from "styled-components";
import {Breakpoint} from "../constants";
import {pageHeight, DoText} from "../Styles";
import doxxBgLinesPage from "../assets/images/doxx-bg-lines-page.png";
import mascot from '../assets/images/doxx-mascot.png';
import doxxLines from '../assets/images/doxx-lines.svg';

export default function LinesPage() {

    function handleRedirectToMain() {
        window.location.reload();
    }

    return (
        <Container>
            <Lines src={doxxLines} />
            <FlexWrapper>
                <FourthPageTextWrap>
                    <FourthPageTexts>
                        <DoText color="#fff" size={6} letterspacing={0.01} fourth>"there are lines</DoText>
                        <DoText color="#fff" size={6} letterspacing={0.01} fourth>that one should</DoText>
                        <DoText color="#fff" size={6} letterspacing={0.01} fourth>never cross.</DoText>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <DoText color="#fff" size={4} letterspacing={0.01} fourth>AT&nbsp;</DoText>
                            <DoText color="#FFC700" size={6} letterspacing={0.01} fourth>DO-XX STUDIO<DoText color="#fff" size={6} fourth>,&nbsp;</DoText></DoText>
                        </div>
                        <DoText color="#fff" size={6} letterspacing={0.01} fourth>we tamper lines.</DoText>
                        <DoText color="#fff" size={6} letterspacing={0.01} fourth>in fact, &nbsp;we'd like to</DoText>
                        <DoText color="#fff" size={6} letterspacing={0.01} fourth><DoText color="#FFC700" size={6} fourth>own</DoText>&nbsp;those lines."</DoText>
                    </FourthPageTexts>
                    <FourthFooter>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <div style={{marginRight:'1vw',marginBottom:'0.5vw'}}>
                                <ScrollArrow white pageNum={7}/>
                            </div>
                            <FourthFooterLogo src={logoDoxxSmallWhite} onClick={handleRedirectToMain}/>
                            {/*<ScrollArrowUpDown src={imageScrollArrowUpDownWhite} style={{marginLeft:'1vw'}}/>*/}
                        </div>
                        <ContactUsWhite marginTop={1} href="mailto:hello@do-xx.com">contact us</ContactUsWhite>
                    </FourthFooter>
                </FourthPageTextWrap>
                <MascotWrapper>
                    <Mascot src={mascot}/>
                </MascotWrapper>
            </FlexWrapper>                
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${doxxBgLinesPage});
    background-size: cover;
    @media (max-width: ${Breakpoint.md}) {
        justify-content: flex-start;
    }
`

const Lines = styled.img`
    width: 100%;
    height: ${props => pageHeight};
    object-fit: cover;
    z-index: 0;
    position: absolute;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`

const FourthPageTextWrap = styled.div`
    height: ${props => pageHeight};
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
    padding-left: 3vw;
`
const FourthPageTexts = styled.div`
    z-index: 10;
    display: flex;
    flex-direction: column;
    @media (max-width: ${props => Breakpoint.md}) {
        padding-right: 10vw;
    }
`
const FourthFooter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    padding-bottom: 5vh;
    @media (max-width: ${props => Breakpoint.md}) {
        padding-bottom: 19vh;       
    }
`
const FourthFooterLogo = styled.img`
    width: 9vh;
    cursor: pointer;
    @media (max-width: ${props => Breakpoint.md}) {
        width: 7vh;
    }
    z-index: 10;
`
const ContactUsWhite = styled('a')<{marginTop?:number}>`
    font-family: syncopate-bold;
    font-size: 2.3vh;
    color: #fff;
    text-decoration: underline;
    margin-top: ${props => props.marginTop || 3.5}vh;
    cursor: pointer;
    white-space: nowrap;
    z-index: 10;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 1.6vh;        
    }
`

const FlexWrapper = styled.div`
    margin-top: -10vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 50vh;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
        margin-top: 0;
    }
`

const MascotWrapper = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
`

const Mascot = styled.img`
    max-width: 40vh;
    z-index: 10;
    object-fit: contain;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`