import React from 'react';
import styled, {keyframes} from "styled-components";
import logoDoxxSliding from "../assets/images/doxx-sliding.svg";
import logoDoxxSmallWhite from "../assets/images/doxx-logo-small-white.svg";
import doxxBgSecondPage from "../assets/images/doxx-bg-second-page.png";
import doxxHeroSecondPage from "../assets/images/doxx-hero-second-page.png";
import doxxIconsSecondPage from "../assets/images/doxx-icons-second-page.png";
import ScrollArrow from "../components/ScrollArrow";
import {Breakpoint} from "../constants";
import {DoText, DoTextSmaller} from "../Styles";

export default function SecondPage() {

    function handleRedirectToMain() {
        window.location.reload();
    }

    return (
        <Container>
            <FlexWrapper>
                <DoTextWrap>
                    <DoText color="#fff">Mobile</DoText>
                    <DoText color="#fff">cloud-based</DoText>
                    <DoText color="#fff">Content Management System (CMS)</DoText>
                </DoTextWrap>
                <HeroImage src={doxxHeroSecondPage} />
            </FlexWrapper>
            <DoUnlockMessageContainer>
                <DoUnlockMessage>
                    <DoTextSmaller color="#fff">Unlock unlimited possibilities</DoTextSmaller>
                    <DoTextSmaller color="#fff">Seamlessly with ease</DoTextSmaller>
                    <UnlockMessageIcons src={doxxIconsSecondPage} />
                </DoUnlockMessage>
            </DoUnlockMessageContainer>
            <DoxxLogoScrolling>
                <DoxxLogoScrollingContent>
                    <DoxxLogoBanner src={logoDoxxSliding} toLeft/>
                    <DoxxLogoBanner src={logoDoxxSliding} toLeft/>
                </DoxxLogoScrollingContent>
            </DoxxLogoScrolling>
            <PageFooter>
                <ContactUs href="mailto:hello@do-xx.com" color="#fff">contact us</ContactUs>
                <BulletLine bulletPos="left" white />
                <FooterLogo src={logoDoxxSmallWhite} onClick={handleRedirectToMain} style={{marginRight:'2vw'}}/>
                {/*<ScrollArrowUpDown src={imageScrollArrowUpDownBlack} style={{marginRight:'1vw'}}/>*/}
                <ScrollArrow pageNum={2} white/>
            </PageFooter>

        </Container>
    );
}


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: url(${doxxBgSecondPage});
    background-size: cover;
`

const DoTextWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
    padding-left: 3vw;
    @media (max-width: ${props => Breakpoint.md}) {
    padding: 5vh 5vw 0 5vw;
        width: 100%;
    }
`
const DOXX_SCROLLING_HEIGHT = 48;
const DoxxLogoScrolling = styled.div`
    position: relative;
    width: 100%;
    height: ${props => DOXX_SCROLLING_HEIGHT}vh;
    display: flex;
    bottom: 50px;
    align-items: center;
`
const DoxxLogoScrollingContent = styled.div`
    display: flex;
    width: auto;
`
const DoxxLogoBanner = styled('img')<{toLeft?:boolean}>`
    height: ${props => DOXX_SCROLLING_HEIGHT * 0.7}vh;
    margin-right: 3vw;
    animation: ${props => props.toLeft ? slideshowToLeft : slideshowToRight} 12s linear infinite; 
`
const slideshowToLeft = keyframes`
    0%{transform: translateX(0);}
    100%{transform: translateX(-100%);}
`
const slideshowToRight = keyframes`
    0%{transform: translateX(-100%);}
    100%{transform: translateX(0%);}
`
const PageFooter = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;    
    align-items: center;
    height: 14vh;
    padding 0 3vw 0 3vw;
`
const ContactUs = styled('a')<{color?:string}>`
    font-family: syncopate-bold;
    font-size: 2.3vh;
    color: ${props => props.color || '#000'};
    text-decoration: underline;
    margin-top: 3.5vh;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: 1.6vh;        
    }
`
const BulletLine = styled('div')<{bulletPos?:string,white?:boolean}>`
    width: 83%;
    height: 1.2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1vh;
    margin-top: 3.5vh;
    ${props => props.bulletPos == 'left' ?
    `
            &:before {
                content: '';
                display: inline-block;
                width: 1.4vh !important;
                height: 1.4vh !important;
                border-radius: 50%;
                background-color: ${props.white ? '#fff' : '#000'};
            }
            &:after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 0.15vh;
                background-color: ${props.white ? '#fff' : '#000'};
                margin: 0 1vh;
            }               
        ` :
    `
            &:before {
                content: '';
                display: inline-block;
                width: 100%;
                height: 0.15vh;
                background-color: ${props.white ? '#fff' : '#000'};
                margin: 0 1vh;
            }
            &:after {
                content: '';
                display: inline-block;
                width: 1.4vh;
                height: 1.4vh;
                border-radius: 50%;
                background-color: ${props.white ? '#fff' : '#000'};
            }        
        `
};
    @media (max-width: ${props => Breakpoint.md}) {
        ${props => props.bulletPos == 'left' ?
    `
                &:before {
                width: 1vh !important;
                height: 1vh !important;
                }
            ` :
    `
                &:after {
                width: 1vh !important;
                height: 1vh !important;
                }                       
            `
}
    }
    
`
const FooterLogo = styled.img`
    width: 5%;
    width: 9vh;
    cursor: pointer;
    @media (max-width: ${props => Breakpoint.md}) {
        width: 7vh;
    }
`

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

const DoUnlockMessageContainer = styled.div`
    display: block;
    padding-right: 3vw;
    margin-top: 5vh;
    position: relative;
    z-index: 1;
    @media (max-width: ${props => Breakpoint.md}) {
        margin-top: 4vh;
    }
`;

const DoUnlockMessage = styled.div`
    background-color: #003755;
    display: inline-flex;
    flex-direction: column;
    width: auto;
    position: relative;
    padding: 2vh 6vw 2vh 3vw;
    border-top-right-radius: 10vh;
    border-bottom-right-radius: 10vh;
    @media (max-width: ${props => Breakpoint.md}) {
    }
`;

const UnlockMessageIcons = styled.img`
    position: absolute;
    left: 86%;
    top: 1vh;
    height: 10vh;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`;

const HeroImage = styled.img`
    max-height: 50vh;
    height: 90%;
    object-fit: cover;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`;